export function getUserRole({ site, userId }) {
  if (site.owners.includes(userId)) {
    return "owner";
  }

  if (site.users.includes(userId)) {
    return "delegate";
  }

  return "admin";
}
